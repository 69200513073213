import React from 'react'

type Props = {
  image?: string
  imageWidth?: number
  headline?: string
  text: string
}

const FeatureListItem: React.FC<Props> = ({
  image = undefined,
  imageWidth = 24,
  headline = undefined,
  text,
}) => (
  <div className="space-y-2 md:space-y-4">
    {image && (
      <div className="text-black flex h-14 w-14 items-center justify-center rounded-xl bg-primary-600 md:h-16 md:w-16">
        <div className="w-5 md:w-auto">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src={image} width={imageWidth} height={imageWidth} alt={headline} />
        </div>
      </div>
    )}
    <h3 className="pt-2 text-sm font-semibold md:min-h-[56px] md:text-base">{headline}</h3>
    <p className="text-sm">{text}</p>
  </div>
)

export default FeatureListItem
