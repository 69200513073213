import React, { ReactNode } from 'react'
import { usePopperTooltip } from 'react-popper-tooltip'
import clsx from 'clsx'

export type Props = {
  children?: ReactNode
  content?: ReactNode
  className?: string
  place?: 'top' | 'right' | 'bottom' | 'left'
}

const Tooltip = ({
  content = undefined,
  children = undefined,
  className = undefined,
  place = 'top',
}: Props) => {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    placement: place,
  })

  if (!content) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }

  return (
    <>
      <div className={className} ref={setTriggerRef}>
        {children}
      </div>

      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: clsx(
              'z-tooltip bg-white border border-gray-300 rounded shadow-tooltip p-2 text-sm',
              className
            ),
          })}
        >
          {content}
        </div>
      )}
    </>
  )
}

export default Tooltip
