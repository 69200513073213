import React from 'react'

import IconBase, { IconProps } from './IconBase'

const IconChevronLeft = ({ size, className }: IconProps) => (
  <IconBase size={size} className={className}>
    <path
      d="m14.53 5.4697c0.2929 0.29289 0.2929 0.76777 0 1.0607l-5.4696 5.4697 5.4696 5.4697c0.2929 0.2929 0.2929 0.7677 0 1.0606s-0.7677 0.2929-1.0606 0l-6-6c-0.29289-0.2929-0.29289-0.7677 0-1.0606l6-6c0.2929-0.29289 0.7677-0.29289 1.0606 0z"
      clipRule="evenodd"
      fillRule="evenodd"
    />
  </IconBase>
)

export default IconChevronLeft
