import React from 'react'
import Image from 'next/image'
import Glide from '@glidejs/glide'
import { GetLandingPageQuery } from 'generated/graphcms'
import IconChevronLeft from 'components/ui/Icon/IconChevronLeft'
import IconChevronRight from 'components/ui/Icon/IconChevronRight'

import '@glidejs/glide/dist/css/glide.core.min.css'

interface Props {
  testimonials: Exclude<GetLandingPageQuery['landingPage'], undefined | null>['testimonials']
}

const Testimonials: React.FC<Props> = ({ testimonials = [] }) => {
  React.useEffect(() => {
    const glideObject = new Glide('.glide')

    glideObject.mount()
  }, [])

  return (
    <div className="glide glide__testimonials group">
      <div className="glide__track" data-glide-el="track">
        <div className="glide__slides">
          {testimonials.map((testimonial) => (
            <div className="glide__slide" key={testimonial.id}>
              <div className="relative w-full space-y-6 lg:flex lg:flex-row-reverse lg:space-x-20 lg:space-y-0 lg:space-x-reverse">
                <div className="text-lg font-medium md:text-2xl lg:w-full lg:max-w-3xl lg:text-3xl">
                  <p className="leading-normal">“{testimonial.quote}”</p>
                </div>

                <div className="flex space-x-4 lg:block lg:flex-1 lg:space-x-0 lg:space-y-3">
                  {testimonial.logo && (
                    <div className="relative max-h-[60px] max-w-[150px]">
                      <Image
                        width={150}
                        height={60}
                        layout="responsive"
                        src={testimonial.logo.url300_120}
                        alt="Logo"
                      />
                    </div>
                  )}
                  <div className="space-y-2 lg:space-y-6">
                    <div>
                      <p className="text-gray-800 md:text-lg">{testimonial.author}</p>
                      {testimonial.authorTitle && (
                        <p className="text-xs text-gray-500 md:text-sm lg:text-lg">
                          {testimonial.authorTitle}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="glide__arrows hidden xl:block" data-glide-el="controls">
        <button
          type="button"
          data-glide-dir="<"
          className="absolute -left-14 bottom-0 top-0 flex w-16 items-center"
        >
          <div className="nav-button flex h-[34px] w-[34px] items-center justify-center rounded-full border border-gray-300 bg-white opacity-0 shadow-lg transition-opacity duration-200 ease-in-out group-hover:opacity-80">
            <IconChevronLeft />
          </div>
        </button>

        <button
          type="button"
          data-glide-dir=">"
          className="absolute -right-14 bottom-0 top-0 flex w-16 items-center justify-end"
        >
          <div className="nav-button flex h-[34px] w-[34px] items-center justify-center rounded-full border border-gray-300 bg-white opacity-0 shadow-lg transition-opacity duration-200 ease-in-out group-hover:opacity-80">
            <IconChevronRight />
          </div>
        </button>
      </div>

      {testimonials.length > 1 && (
        <div className="mt-2 flex w-full justify-center md:mt-16" data-glide-el="controls[nav]">
          {testimonials.map(({ id }, index) => (
            <button
              key={id}
              type="button"
              title={`Go to slide #${index + 1}`}
              className="flex h-5 w-5 items-center justify-center"
              data-glide-dir={`=${index}`}
            >
              <span className="bullet h-2 w-2 rounded-full bg-gray-300">
                <span className="sr-only">
                  Select
                  {index}
                  slide
                </span>
              </span>
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

export default Testimonials
