import React from 'react'

import IconBase, { IconProps } from './IconBase'

const IconMinus = ({ size, className }: IconProps) => (
  <IconBase size={size} className={className}>
    <path
      d="m4 12c0-0.49092 0.39797-0.88889 0.88889-0.88889h14.222c0.49095 0 0.88889 0.39797 0.88889 0.88889 0 0.49095-0.39794 0.88889-0.88889 0.88889h-14.222c-0.49092 0-0.88889-0.39794-0.88889-0.88889z"
      clipRule="evenodd"
      fillRule="evenodd"
      strokeWidth="1.4222"
    />
  </IconBase>
)

export default IconMinus
