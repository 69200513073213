import React, { FormEvent, useMemo, useState } from 'react'
import Image from 'next/legacy/image'

import FormInputNumber from 'components/ui/FormInputNumber/FormInputNumber'
import IconUsers from 'components/ui/Icon/IconUsers'
import Button from 'components/ui/Button/Button'
import { useTranslation } from 'next-i18next'
import FilterSelect, { Item } from '@/components/ui/FilterSelect/FilterSelect'
import { querifyItems } from '@/components/shared/Venues/VenueFilter'
import { useRouter } from 'next/router'
import { Area, Locale } from 'generated/graphcms'
import { APP_ROUTES, MAX_GUESTS, MIN_GUESTS } from 'utils/constants'
import usePreviewGroupSize from 'hooks/usePreviewGroupSize'
import { triggerHotJarEvent } from '../../../utils/tracking'

interface Props {
  areas: Area[]
}

const Hero: React.FC<Props> = ({ areas = [] }) => {
  const [selectedGroupSize, setSelectedGroupSize] = useState<string | number>(MIN_GUESTS)

  const memoizedAreas = useMemo(
    () => areas.map((area) => ({ id: area.slug, label: area.title })),
    [areas]
  )

  const preselection = memoizedAreas.length === 1 ? [memoizedAreas[0]] : []
  const [selectedAreas, setSelectedAreas] = useState<Item[]>(preselection)
  const { setLastSearchedGroupSize } = usePreviewGroupSize()

  const onGroupSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedGroupSize(event.target.value)

    const newSize = parseInt(event.target.value, 10)

    if (!Number.isNaN(newSize)) {
      setLastSearchedGroupSize(newSize)
    }
  }

  const router = useRouter()
  const { locale = Locale.En } = router

  const changeSelectedArea = (selection: Item[]) => {
    if (selection.length === 0) {
      const event = locale === Locale.De ? 'selected_any_area_de' : 'selected_any_area'
      triggerHotJarEvent(event)
    }

    setSelectedAreas(selection)
  }

  const updateQuery = (e: FormEvent) => {
    e.preventDefault()
    const newQuery: { [id: string]: string | number | boolean } = {}

    if (selectedAreas.length > 0) {
      newQuery.areas = querifyItems(selectedAreas)
    }

    if (selectedGroupSize !== MIN_GUESTS) {
      newQuery.groupSize = selectedGroupSize
    }

    if (Object.keys(newQuery).length > 0) {
      router.push({ pathname: APP_ROUTES.VENUES_SEARCH, query: newQuery })
    } else {
      router.push({ pathname: APP_ROUTES.VENUES })
    }
  }

  const { t } = useTranslation()

  return (
    <div className="relative -mt-20">
      <div className="flex flex-col-reverse md:grid md:grid-cols-2">
        <div className="relative z-20 -mt-14 pl-4 pr-4 md:mt-0 md:pl-10 md:pr-0 xl:pl-20">
          <div className="mx-auto max-w-[640px] text-center md:ml-auto md:mr-0 md:pt-20 md:text-left lg:pt-40">
            <div className="relative space-y-4 md:space-y-8">
              <div className="absolute left-[-40%] top-0 z-[-1] hidden h-80 w-96 rounded-full bg-primary-600 opacity-30 blur-3xl md:block" />

              <div className="text-4xl font-bold md:text-5xl">
                <h1 className="leading-tight">{t('landing.hero.title')}</h1>
              </div>

              <div className="mx-auto max-w-[480px] text-sm text-gray-600 md:ml-0 md:text-xl">
                <p className="leading-relaxed">{t('landing.hero.description')}</p>
              </div>

              <form
                onSubmit={updateQuery}
                className="mx-auto max-w-sm space-y-4 pt-2 md:ml-0 md:pt-0"
              >
                <div>
                  <FilterSelect
                    wildcardLabel={t('filters.anyArea')}
                    items={memoizedAreas}
                    selectedItems={selectedAreas}
                    onSelectedItemsChange={changeSelectedArea}
                    checkType="checkbox"
                    cy="destinations"
                  />
                </div>

                <div>
                  <FormInputNumber
                    id="guests"
                    value={selectedGroupSize}
                    min={MIN_GUESTS}
                    step={1}
                    max={MAX_GUESTS}
                    tooltipMin={t('filters.guestsMinHint', { count: MIN_GUESTS })}
                    tooltipMax={t('filters.guestsMaxHint', { count: MAX_GUESTS })}
                    Icon={IconUsers}
                    onChange={onGroupSizeChange}
                    cy="guests"
                  />
                </div>

                <div className="pt-2">
                  <Button type="submit" className="w-full" cy="search-button">
                    {t('landing.hero.searchButton')}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="relative z-10 md:pl-10 lg:pl-20">
          <div className="ml-auto hidden max-w-2xl md:block">
            <Image
              src="/images/hero/cloopio-home-venues-v2.jpg"
              alt="image"
              width={1220}
              height={1700}
              layout="responsive"
            />
          </div>

          <div className="h-[220px] md:hidden md:h-[440px]">
            <Image
              src="/images/hero/cloopio-home-venues-mobile-v2.jpg"
              alt="image"
              layout="fill"
              objectFit="cover"
              quality={100}
            />
          </div>

          <div className="pointer-events-none absolute bottom-0 left-0 z-10 h-64 w-full bg-gradient-to-t from-white" />
        </div>
      </div>
    </div>
  )
}

export default Hero
