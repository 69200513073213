import React from 'react'
import { GetLandingPageQuery, Locale, Area } from 'generated/graphcms'

import Layout from 'components/layout/Layout'
import Container from 'components/ui/Container/Container'
import FeatureList from 'components/ui/FeatureList/FeatureList'
import FeatureListItem from 'components/ui/FeatureList/FeatureListItem'

import Hero from 'components/shared/Home/Hero'
import Testimonials from 'components/shared/Home/Testimonials'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import { getFilterData } from 'components/shared/Venues/getFilterData'
import { FooterData, getFooterData } from 'components/layout/AppShell/getFooterData'
import Head from 'next/head'
import { getLandingPageData } from 'components/shared/Venues/getLandingPageData'
import Image from 'next/legacy/image'

interface Props {
  landingPage: GetLandingPageQuery['landingPage']
  areas: Area[]
  footerData: FooterData
}

export async function getStaticProps({ locale }: { locale: Locale }) {
  const landingPage = await getLandingPageData(locale)
  const filterData = await getFilterData(locale)

  return {
    props: {
      areas: filterData.areas,
      landingPage,
      footerData: await getFooterData(locale),
      ...(await serverSideTranslations(locale, ['common'])),
    },
  }
}

const Home: React.FC<Props> = ({ landingPage, areas, footerData }) => {
  const { t } = useTranslation()

  return (
    <Layout isPublic footerData={footerData}>
      <Head>
        <title>{t('landing.siteTitle')}</title>
        <meta name="description" content={t('landing.metaDescription')} />
        <meta name="facebook-domain-verification" content="8r42uw9l0s10qtjxpvacq8vb0e2ow3" />
        <meta property="og:title" content={t('landing.siteTitle')} />
        <meta property="og:description" content={t('landing.metaDescription')} />
      </Head>
      <Hero areas={areas} />

      <div className="overflow-hidden">
        <Container>
          {landingPage?.customers && landingPage?.customers.length > 0 && (
            <div className="space-y-12 py-12 md:space-y-20 md:py-24 md:text-lg">
              <div>
                <div className="-my-8 flex flex-row flex-wrap items-center justify-center">
                  {landingPage.customers.map((customer) => (
                    <div key={customer.id} className="mx-11 my-8">
                      <div className="flex h-[130px] items-center">
                        {(() => {
                          if (customer.logo?.mimeType?.includes('svg')) {
                            return (
                              customer.logo?.url444_330 &&
                              customer.logo?.url && (
                                // eslint-disable-next-line @next/next/no-img-element
                                <img
                                  src={customer.logo?.url}
                                  width={165}
                                  height={123}
                                  alt={customer.title || 'Customer'}
                                />
                              )
                            )
                          }

                          return (
                            customer.logo?.url444_330 && (
                              <Image
                                src={customer.logo?.url444_330}
                                width={165}
                                height={123}
                                alt={customer.title || 'Partner'}
                              />
                            )
                          )
                        })()}
                      </div>

                      {customer.title && <div className="mt-2">{customer.title}</div>}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          <div className="border-b border-t border-gray-200 py-12 md:pb-16 md:pt-24">
            {(landingPage?.testimonials?.length || -1) > 0 && (
              <Testimonials testimonials={landingPage!.testimonials} />
            )}
          </div>

          <div className="border-b border-gray-200 py-12 md:py-16">
            <div className="mx-auto max-w-[900px]">
              <div className="relative w-full pb-[56.25%]">
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video
                  className="absolute bottom-0 left-0 right-0 top-0 h-full w-full"
                  poster="/videos/a_day_with_cloopio.png"
                  controls
                >
                  <source src="/videos/cloopio_1_min_highlights.mp4" />
                </video>
              </div>
            </div>
          </div>

          <div className="space-y-20 border-b border-gray-200 py-12 md:py-24 ">
            <div className="space-y-5 text-center">
              <h2 className="mx-auto max-w-xl text-2xl font-semibold md:text-4xl">
                {t('landing.features.title')}
              </h2>
            </div>

            <FeatureList>
              {landingPage?.features.map((feature) => (
                <FeatureListItem
                  key={feature.id}
                  image={feature.icon?.url}
                  headline={feature.title || ''}
                  text={feature.description || ''}
                />
              ))}
            </FeatureList>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default Home
