import React, { InputHTMLAttributes, useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'

import Tooltip from 'components/ui/Tooltip/Tooltip'
import { IconComponent } from '@/components/ui/Icon/IconBase'
import IconMinus from '../Icon/IconMinus'
import IconPlus from '../Icon/IconPlus'

type Props = {
  Icon?: IconComponent
  tooltipMax?: string
  tooltipMin?: string
  title?: string
  cy?: string
  className?: string
  error?: boolean
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>

const FormInputNumber: React.FC<Props> = React.forwardRef<HTMLInputElement, Partial<Props>>(
  (
    {
      Icon = undefined,
      tooltipMax = '',
      tooltipMin = '',
      title = '',
      cy = undefined,
      className = '',
      error = false,
      ...props
    },
    forwardedRef
  ) => {
    const ref = useRef<HTMLInputElement | null>(null)

    const step = (direction: 'up' | 'down') => {
      const currentValue = Number(ref?.current?.value)
      if (Number.isNaN(currentValue) || currentValue === 0) {
        if (ref?.current) {
          ref.current.value = '0'
        }
      }

      try {
        if (direction === 'up') {
          ref?.current?.stepUp()
        } else {
          ref?.current?.stepDown()
        }
        // eslint-disable-next-line no-empty
      } catch {}

      // stepUp/stepDown do not seem to trigger the onChange event, so I'm triggering it manually
      ref?.current?.dispatchEvent(new Event('input', { bubbles: true }))
    }

    const [valueNumber, setValueNumber] = useState(props.value || 0)

    useEffect(() => {
      setValueNumber(Number(props.value) || 0)
    }, [props.value])

    // This to make it work with packages that updates the ref.current.value
    useEffect(() => {
      setValueNumber(Number(ref.current?.value))
    }, [])

    const minReached = useMemo(() => {
      if (typeof props.min !== 'number') {
        return false
      }

      return Number(valueNumber) <= props.min
    }, [valueNumber, props.min])

    const maxReached = useMemo(() => {
      if (typeof props.max !== 'number') {
        return false
      }

      return Number(valueNumber) >= props.max
    }, [valueNumber, props.max])

    return (
      <div className={`${props.disabled && 'cursor-not-allowed'} ${className}`}>
        <span className="text-sm text-gray-500">{title}</span>
        <div
          className={`block flex h-10 w-full rounded-lg border border-gray-300 pb-px pl-3 pt-0 text-sm leading-none text-gray-800 placeholder-gray-400 focus-within:outline-none focus-within:ring-2 focus-within:ring-gray-100 
          ${error && 'border-red-600'}
          `}
        >
          {Icon && (
            <div className="pointer-events-none mr-3 flex h-10 w-6 items-center justify-center text-gray-500">
              <Icon size="24" />
            </div>
          )}
          <input
            type="number"
            ref={(r) => {
              ref.current = r
              if (typeof forwardedRef === 'function') {
                forwardedRef?.(r)
              }
            }}
            {...props}
            onInput={(e) => {
              props.onInput?.(e)
              setValueNumber(Number(ref.current?.value) || 0)
            }}
            onWheel={(e) => (e.target as HTMLInputElement).blur()}
            className={`mr-2 min-w-0 flex-grow focus:outline-none ${
              props.disabled && 'cursor-not-allowed'
            }`}
            data-cy={cy ? `${cy}-input` : undefined}
          />
          <div className="mx-3 ml-auto flex h-full items-center">
            <Tooltip place="top" content={minReached ? tooltipMin : ''}>
              <button
                type="button"
                onClick={() => step('down')}
                disabled={minReached || props.disabled}
                className={clsx(
                  'focus-element mr-2 flex h-7 w-7 items-center justify-center rounded-full bg-gray-100 text-gray-500',
                  props.disabled || minReached ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
                )}
                data-cy={cy ? `${cy}-down` : undefined}
              >
                <IconMinus className="w-[14px]" />
              </button>
            </Tooltip>

            <Tooltip place="top" content={maxReached ? tooltipMax : ''}>
              <button
                type="button"
                onClick={() => step('up')}
                disabled={maxReached || props.disabled}
                className={clsx(
                  'focus-element flex h-7 w-7 items-center justify-center rounded-full bg-gray-100 text-gray-500',
                  props.disabled || maxReached ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
                )}
                data-cy={cy ? `${cy}-up` : undefined}
              >
                <IconPlus className="w-[14px]" />
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
    )
  }
)

FormInputNumber.displayName = 'FormInputNumber'

export default FormInputNumber
