import React from 'react'

import IconBase, { IconProps } from './IconBase'

const IconPlus = ({ size, className }: IconProps) => (
  <IconBase size={size} className={className}>
    <path
      d="m12 4c0.49095 0 0.88889 0.39797 0.88889 0.88889v6.2222h6.2222c0.49095 0 0.88889 0.39797 0.88889 0.88889 0 0.49095-0.39794 0.88889-0.88889 0.88889h-6.2222v6.2222c0 0.49095-0.39794 0.88889-0.88889 0.88889-0.49092 0-0.88889-0.39794-0.88889-0.88889v-6.2222h-6.2222c-0.49092 0-0.88889-0.39794-0.88889-0.88889 0-0.49092 0.39797-0.88889 0.88889-0.88889h6.2222v-6.2222c0-0.49092 0.39797-0.88889 0.88889-0.88889z"
      clipRule="evenodd"
      fillRule="evenodd"
      strokeWidth="1.4222"
    />
  </IconBase>
)

export default IconPlus
