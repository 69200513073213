import React from 'react'

export type Props = {
  children: React.ReactNode
}

const FeatureList: React.FC<Props> = ({ children }) => (
  <div className="grid grid-cols-1 gap-10 md:gap-16 lg:grid-cols-3">{children}</div>
)

export default FeatureList
